import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { GameClientService, ScoreBoardResponseDedicated } from '../game-client.service';
import { Subscription } from 'rxjs';
import { GuiStateService } from '../gui-state.service';

@Component({
  selector: 'app-stats-details',
  templateUrl: './stats-details.component.html',
  styleUrls: ['./stats-details.component.scss']
})
export class StatsDetailsComponent implements OnInit, OnDestroy {

  // injected from url get param
  public wholeSeason: boolean;
  public startPeriod: string;
  public endPeriod: string;
  public roomId: number;
  public isLoading: boolean = true;
  public scoreboard: ScoreBoardResponseDedicated = undefined;
  public completedNotifier: string = "";

  public displayedColumns: any = [
    'position',
    'displayName',
    'correct'
  ];

  private scoresDedicatedUpdatedSubscription: Subscription;
  public pageSize: number = 10;
  public pageIndex: number = 0;
  public length1: number = 0;



  constructor(
    private gameClientService: GameClientService,
    private guiStateService: GuiStateService
  ) {
  }

  ngOnInit() {
    this.guiStateService.showBackLink = true;
    this.guiStateService.titleBarText = "Statistics - Details";
    this.guiStateService.setZoom100();


    this.wholeSeason = this.gameClientService.currentStatsDetails.wholeSeason;
    this.startPeriod = this.gameClientService.currentStatsDetails.startDateTime;
    this.endPeriod = this.gameClientService.currentStatsDetails.endDateTime;
    this.roomId = this.gameClientService.currentStatsDetails.roomId;

    this.subscribeToStats();

    // one-off request scoreboard for this instance's time period
    this.gameClientService.getSeasonScores(this.startPeriod, this.endPeriod, this.roomId, this.pageIndex, this.pageSize);
  }

  ngOnDestroy() {
    if(this.scoresDedicatedUpdatedSubscription)
      this.scoresDedicatedUpdatedSubscription.unsubscribe();
  }

  public getScoresPage(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    console.log(this.pageSize + ", " + this.pageIndex + ", " + this.length1);

    this.gameClientService.getSeasonScores(this.startPeriod, this.endPeriod, this.roomId, this.pageIndex, this.pageSize)
  }

  private subscribeToStats(): void {
    this.isLoading = true;
    this.scoresDedicatedUpdatedSubscription = this.gameClientService.scoresDedicatedUpdatedEvent.subscribe(scoreboardResponse => {

      scoreboardResponse.Scores = scoreboardResponse.Scores.map((scoreLine, index) => {
        return { Position: this.pageSize * this.pageIndex + index + 1, DisplayName: scoreLine.DisplayName, Correct: scoreLine.Correct, Me: scoreLine.Me }
      });

      this.scoreboard = scoreboardResponse;
      this.isLoading = false;
      this.completedNotifier = scoreboardResponse.SeasonFinished ? "(Finished)" : "(In Progress)";
      console.log("loaded stats, and adding more lines");
      const originalLength = scoreboardResponse.Scores.length;

      this.length1 = scoreboardResponse.Length;

      const targetPageLength = this.pageSize;

      while(this.scoreboard.Scores.length < targetPageLength) { 
        this.scoreboard.Scores.push({ Position: -1, DisplayName: "", Correct: -1, Me: false  });
      }

    });

  }
}

