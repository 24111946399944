<div style="height: 100%; margin: 0 auto" class="set-max-width">
  <app-title-navigation-bar></app-title-navigation-bar>
  <div align="center">

    <div class="title-button-container-div flex-row d-flex justify-content-sm-between room-row-bg">
      <button mat-raised-button class="title-option" style="display: flex; flex-flow: row; justify-content: center; align-items: center;" (click)="enterRoom(0)">
        Worldwide 24/7 All Categories
      </button>

      <span class="stats-button-main-gap"> </span>

      <button mat-raised-button class="title-option-stats" style="display: flex; flex-flow: row; justify-content: center; align-items: center;" (click)="showStats(0)">
        Stats
      </button>
    </div>

    <!--
    <div class="title-button-container-div flex-row d-flex justify-content-sm-between room-row-bg">
      <button mat-raised-button class="title-option" (click)="enterRoom(1)">
        Australia Nightly All Categories
      </button>

      <span class="stats-button-main-gap"> </span>

      <button mat-raised-button class="title-option-stats" (click)="showStats(1)">
        Stats
      </button>
    </div>
    -->

    <div class="title-button-container-div flex-row d-flex justify-content-sm-between room-row-bg">
      <button mat-raised-button class="title-option" style="display: flex; flex-flow: row; justify-content: center; align-items: center;" (click)="enterRoom(4)">
        Worldwide 24/7 Math
      </button>

      <span class="stats-button-main-gap"> </span>

      <button mat-raised-button class="title-option-stats" style="display: flex; flex-flow: row; justify-content: center; align-items: center;" (click)="showStats(4)">
        Stats
      </button>
    </div>


    <div class="floatah">

    </div>

    <div>
      <div class="otd-note">This game uses questions sourced from:</div>
      <div style="height: 10px"></div>
      <div><a href="https://opentdb.com/" target="_blank"><img src="https://opentdb.com/images/logo-banner.png"/></a>
      </div>
    </div>

  </div>

</div>
