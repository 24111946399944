import { Component, OnInit } from '@angular/core';
import { MatInputModule, MatSelect } from '@angular/material';
import { GameClientService } from '../game-client.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { GuiStateService } from '../gui-state.service';
import Moment from 'moment-timezone';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginError: string;
  _tab: number = 0;
  displayName: string
  email: string
  password: string
  signedUpWaitingForActivation: boolean = false;
  userAlreadyExists: boolean = false;
  emailAtSubmitTime: string = "";

  timestamp: any;
  timestamp2: any;

  loginSubscription: Subscription;

  constructor(
    private gameClientService: GameClientService,
    private router: Router,
    private guiStateService: GuiStateService,
  ) {

  }

  ngOnInit() {
    this.guiStateService.showMainLink = false;
    this.guiStateService.showLogout = false;
    this.guiStateService.titleBarText = "Dolphin Trivia";
    this.guiStateService.setZoom100();

  }

  get tab(): number {
    return this._tab;
  }

  setRegistering(tabNumber: number) {
    this._tab = tabNumber;
  }

  ngOnDestroy() { 
  }

  public guestPlay(): void {
    console.log("attempting login as guest");
    this.loginSubscription = this.gameClientService.postLogin("guest", "guest")
      .subscribe(
        response => {
          this.loginSubscription.unsubscribe();
          console.log(response);
          console.log("navigating to title");
          this.loginError = "";
          this.gameClientService.setToken(response.Token);
          this.gameClientService.setDisplayName(response.DisplayName);
          this.gameClientService.setEmail(response.Email);  
          this.router.navigate(["title"], { skipLocationChange: false });
        },
        error => {
          console.log(error);
          this.loginError = "Guest accounts all in use at the moment";
        }
      )
  }

  public login(): void {
    console.log("attempting login as proper account");
    this.loginSubscription = this.gameClientService.postLogin(this.email, this.password)
      .subscribe(
        response => {
          console.log("login response good");
          this.loginSubscription.unsubscribe();
          console.log(response);
          console.log("navigating to title");
          this.loginError = "";
          this.gameClientService.setToken(response.Token);
          this.gameClientService.setDisplayName(response.DisplayName);
          this.gameClientService.setEmail(response.Email);
          this.router.navigate(["title"], { skipLocationChange: false });
        },
        error => {
          console.log("login response error");
          console.log(error);
          this.loginSubscription.unsubscribe();
          if(error.error.Status === "bad_username_or_password") {
            this.loginError = "Incorrect username or password";
          } else {
            this.loginError = "Unknown error";
          }
          this.gameClientService.clearAuth();
        }
      )
  }

  public signupInitial(): void {
    console.log("attempting signup");
    this.emailAtSubmitTime = this.email;

    this.loginSubscription = this.gameClientService.postSignupInitial(this.email, this.password, this.displayName)
      .subscribe(
        response => {
          this.loginSubscription.unsubscribe();
          console.log(response);
          if (response.Status == "user_already_exists") {
            this.userAlreadyExists = true;
            this.signedUpWaitingForActivation = false;
          } 
          else if (response.Status == "user_successfully_created_requires_activation") {
            this.signedUpWaitingForActivation = true;
            this.userAlreadyExists = false;
          }
        },
        error => {
          console.log("Error: " + error);    
        }
      )
  }

}
