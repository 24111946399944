<div style="height: 100%; margin: 0 auto" class="set-max-width">
  <app-title-navigation-bar></app-title-navigation-bar>

  <div align="center" class="greyback login-containing-div" style="height: 50%;">

    <!-- TODO: check these ngclass statements, was done in  a hurry, not tested yet-->
    <ul class="nav unselected-tab">
      <li class="nav-link" [ngClass]="{'active, selected-tab': tab === 0 }" (click)="setRegistering(0)">
        <span>Login</span></li>

          <!--
        <li class="nav-link" [ngClass]="{'active, selected-tab': tab === 1 }" (click)="setRegistering(1)">
          <span>Sign up</span></li>
        -->
        
          
          <li class="nav-link" [ngClass]="{'active, selected-tab': tab === 2 }" (click)="setRegistering(2)">
        <span>Guest</span></li>
        
      </ul>

    <div class="table-holder"
      [ngClass]="{'login-table-holder-registering': tab === 1 , 'login-table-holder': tab === 0 , 'login-table-holder-guest': tab === 2}">

      <table align="center" style="border: none; background: #222222; width: 100%;" *ngIf="tab === 0 || tab === 1">

        <col style="width: 66%;">
        <col style="width: 33%;">

        <tr *ngIf="tab == 0 || tab == 1">

          <td class="login-left">
            <form>

              <div class="tph" *ngIf="tab === 0 || tab === 1">
                <mat-form-field class="form-full-width">
                  <input matInput value="" [(ngModel)]="email" name="emailInput" type="text" class="form-text">
                  <mat-placeholder class="placeholder-text-white">Email</mat-placeholder>
                </mat-form-field>
              </div>

              <div class="tph" *ngIf="tab === 0 || tab === 1">
                <mat-form-field class="form-full-width">
                  <input matInput value="" [(ngModel)]="password" name="passwordInput" type="password" class="form-text">
                  <mat-placeholder class="placeholder-text-white">Password</mat-placeholder>
                </mat-form-field>
              </div>

              <div class="tph" *ngIf="tab === 1">
                <mat-form-field class="form-full-width">
                  <input matInput value="" [(ngModel)]="displayName" name="displayNameInput" class="form-text">
                  <mat-placeholder class="placeholder-text-white">Display name</mat-placeholder>
                </mat-form-field>
              </div>

              <div *ngIf="tab === 0" class="tph">
              </div>


            </form>

          </td>

          <td class="login-middle" >
            <div *ngIf="tab === 0" style="display: flex; flex-flow: row; justify-content: center; align-items: center;">
              <button mat-raised-button color="primary" (click)="login()" type="submit"
                class="round-corners-login-buttons">Login</button>
            </div>

              
            <div *ngIf="tab === 1" style="display: flex; flex-flow: row; justify-content: center; align-items: center;">
              <button mat-raised-button color="primary" (click)="signupInitial()" type="submit"
                class="round-corners-login-buttons">Sign Up</button>
            </div>
          

            <div class="verticalspace"></div>

          </td>
        </tr>
        <tr>

          <td [ngClass]="{'login-middle': tab === 0 || tab == 1 }" colspan="2" class="below-form-text">
            <div *ngIf="tab === 0">

              <!--
              <div>
              <div>Not signed up yet? </div>
            </div> 
              -->

            <div style="height: 20px" *ngIf="tab == 0"></div>

            <div>
              
               <!-- TODO: #125930 -->
               <!--
                    <div>Click the "Sign Up" tab to sign up!</div>
               -->
               <div>Sign Up tab is temporarily disabled.</div>
               <div>Click the "Guest" tab to play as a guest!</div>
                
              </div>
            </div>
          
            <div *ngIf="tab === 1">
              <div>Already signed up? Click the "Login" tab to login!</div>
            </div>
            
            <div style="height: 20px" *ngIf="tab == 0 || tab == 1"></div>
          
            <div *ngIf="tab === 1 && signedUpWaitingForActivation == false && userAlreadyExists == false">
              Display name, email address and password can only contain letters, numbers, periods or spaces.
            </div>
            <div *ngIf="signedUpWaitingForActivation == true" style="color: green">
              Email has been sent to {{emailAtSubmitTime}} ! Open it to activate your Dolphin Trivia account and start
              playing!
            </div>
            <div *ngIf="userAlreadyExists == true" style="color: red">
              Email address is already in use!
            </div>
          
          
          
          </td>

        </tr>

      </table>

      <div *ngIf="tab === 2" style="display: flex; flex-flow: column; justify-content: center; align-items: center; height: 100%; min-height: 100%;" >
        <div style="display: flex; flex-flow: row; justify-content: center; align-items: center; height: 100%;" >
            <button mat-raised-button color="primary" (click)="guestPlay()" type="submit"
              class="round-corners-login-buttons">Guest Play</button>
        </div>

        <div>
          <div>Click Guest Play to play with a temporary Guest username.</div>
        </div> 

      </div>


    </div>

    <div *ngIf="loginError !== ''">
      <span class="error-message">{{loginError}}</span>
    </div>
  </div>

  <!--
  <div class="container w-25" style="color: white; background-color: grey">
    <div class="row">
      <div class="col-sm p-3">
        One of three columns
      </div>
      <div class="col-sm">
        One of three columns
      </div>
      <div class="col-sm">
        One of three columns
      </div>
    </div>
  </div>

  <div style="display: flex; align-items: center; justify-content: center;">
  <div style="color: #ffffff">
      Current time 1: {{timestamp}}
      <hr />
      Current time 2: {{timestamp2}}
    </div>
  </div>
-->


</div>