import { Component, OnInit, OnDestroy } from '@angular/core';
import { GameClientService } from '../game-client.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-previous-answer-status',
  templateUrl: './previous-answer-status.component.html',
  styleUrls: ['./previous-answer-status.component.css']
})
export class PreviousAnswerStatusComponent implements OnInit, OnDestroy {

  public previousQuestion: string;
  public correctAnswer: string;
  public chosenAnswer: string;
  public isTooLate: boolean;
  public isCorrect: boolean;
  public isPreviousAvailable: boolean = true;

  previousAnswerSubscription: Subscription;
  isCancelled: boolean;

  constructor(private gameClientService: GameClientService) {

  }

  ngOnDestroy() {
    if(this.previousAnswerSubscription) 
      this.previousAnswerSubscription.unsubscribe();
  }

  ngOnInit() {

    this.displayPreviousCorrectAnswer();

    this.previousAnswerSubscription = this.gameClientService.previousAnswerEvent.subscribe(
      () => {
        this.displayPreviousCorrectAnswer();
      }
    );    
  }


  private displayPreviousCorrectAnswer(): void {
    let previousQuestion: string;
    let correctAnswer: string;
    let chosenAnswer: string;

    if(
      this.gameClientService.state.previousCorrectAnswer === null || 
      this.gameClientService.state.previousCorrectAnswer.Cancelled === true
      ) {
      this.isPreviousAvailable = false;
      return;
    } 
    
    else {
      this.isPreviousAvailable = true;
    }

    if (this.gameClientService.state.previousCorrectAnswer.AnsweredOnTime == false) {
      this.isTooLate = true;
      return;
    } else {
      this.isTooLate = false;
    }

    previousQuestion = this.gameClientService.state.previousQuestion.Question;
  
    let correctIndex: number = this.gameClientService.state.previousCorrectAnswer.CorrectOption - 1;
    correctAnswer = this.gameClientService.state.previousQuestion.Options[correctIndex];

     let chosenIndex: number = this.gameClientService.state.previousUserSelectedAnswerNumber - 1;
     chosenAnswer = this.gameClientService.state.previousQuestion.Options[chosenIndex];

      this.previousQuestion = previousQuestion;
      this.correctAnswer = correctAnswer;
      this.chosenAnswer = chosenAnswer;

      this.isCorrect = correctIndex == chosenIndex;

      this.isCancelled = this.gameClientService.state.previousCorrectAnswer.Cancelled;

    }

}
