import { Component, OnInit, ElementRef } from '@angular/core';
import { GameClientService, ScoreBoardResponseGameplay, ScoreBoardEntryGameplay } from '../game-client.service';
import Moment from 'moment-timezone';
import { Input } from '@angular/core';
import { getLocaleDayPeriods } from '@angular/common';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-gameplay-score-table',
  templateUrl: './gameplay-score-table.component.html',
  styleUrls: ['./gameplay-score-table.component.css']
})
export class GameplayScoreTableComponent implements OnInit {

  @Input()
  public timePeriodString: string;

  public timePeriodNumber: number;
  public scores: ScoreBoardResponseGameplay = undefined;

  scoresGameplayUpdatedSubscription: Subscription;
  questionEndedSubscription: Subscription;

  constructor(
    private gameClientService: GameClientService,
    private elementRef: ElementRef
  ) {

  }

  ngOnDestroy() {
    console.log("ON DESTROY")

        if(this.scoresGameplayUpdatedSubscription)
      this.scoresGameplayUpdatedSubscription.unsubscribe();
  
    if(this.questionEndedSubscription)
      this.questionEndedSubscription.unsubscribe();
  }

  ngOnInit() {

    console.log("ON INIT")

    this.setPeriodString();

    // subscribe to all scoreboard updates
    this.scoresGameplayUpdatedSubscription = this.gameClientService.scoresGameplayUpdatedEvent.subscribe(scoreboardResponse => {
      if (scoreboardResponse.TimePeriodSeconds == this.timePeriodNumber) {
        scoreboardResponse.Scores = scoreboardResponse.Scores.map((scoreLine, index) => {
          return { Position: index + 1, DisplayName: scoreLine.DisplayName, Correct: scoreLine.Correct, Me: scoreLine.Me }
        });

        /*
                   const originalLength = scoreboardResponse.Scores.length;   
        
                   for(let i = 0; i < 200; i++) {
                    const displayName = "Player" + Math.floor((Math.random() * 100000) % 100);
                    const newScoreLine = { Position: i + originalLength, DisplayName: displayName, Correct: 70, Me: false };
                    scoreboardResponse.Scores.push(newScoreLine);
                  }
            
                  scoreboardResponse.Scores.sort((a, b) => (b.Correct - a.Correct));
        */
        // keep reference to my entry, so we can add it on after slicing afterwards if we need to.
        const meEntry: ScoreBoardEntryGameplay = scoreboardResponse.Scores.find(score => score.Me === true);

        scoreboardResponse.Scores = scoreboardResponse.Scores.slice(0, 20);

        // create blank rows for view if the number of players is less than 20. this is to make sure too-few rows don't expand vertically.
        const shortBy = 20 - scoreboardResponse.Scores.length;
        if (shortBy > 0) {
          for (let i = 0; i < shortBy; i++) {
            const newScoreLine = { Position: -1, DisplayName: "_blank", Correct: -1, Me: false };
            scoreboardResponse.Scores.push(newScoreLine);
          }
        } else {
          if (scoreboardResponse.Scores.find(score => score.Me === true)) { } else {
            scoreboardResponse.Scores = scoreboardResponse.Scores.slice(0, 19);
            scoreboardResponse.Scores.push(meEntry);
          }
        }

        this.scores = scoreboardResponse;
      }
    });

    // initial load
    // only load if the component is actually visible.
    if (this.elementRef.nativeElement.offsetParent !== null) {
      this.gameClientService.requestGameplayScoreboard(this.timePeriodNumber);
    }

    this.questionEndedSubscription = this.gameClientService.questionEndedEvent.subscribe(
      () => {
        // only load if the component is actually visible.
        if (this.elementRef.nativeElement.offsetParent !== null) {
          this.gameClientService.requestGameplayScoreboard(this.timePeriodNumber);
        }
      }
    );
  }

  private setPeriodString(): void {
    if (this.timePeriodString === "Hour") {
      this.timePeriodNumber = 60 * 60;
    } else if (this.timePeriodString === "Day") {
      this.timePeriodNumber = 60 * 60 * 24;
    }
  }

}

