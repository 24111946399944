import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TitleScreenComponent } from './title-screen/title-screen.component';
import { AppComponent } from 'src/app/app.component';
import { LoginComponent } from './login/login.component';
import { GameplayComponent } from 'src/app/gameplay/gameplay.component';
import { StatsComponent } from 'src/app/stats/stats.component';
import { StatsDetailsComponent } from 'src/app/stats-details/stats-details.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'title', component: TitleScreenComponent },
  { path: 'gameplay', component: GameplayComponent },
  { path: 'stats', component: StatsComponent },
  { path: 'statsdetails', component: StatsDetailsComponent }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {

}
