import { Component, OnInit } from '@angular/core';
import { GameClientService, PreviousCorrectAnswer } from '../game-client.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { GuiStateService } from '../gui-state.service';

@Component({
  selector: 'app-title-screen',
  templateUrl: './title-screen.component.html',
  styleUrls: ['./title-screen.component.css']
})
export class TitleScreenComponent implements OnInit {

  constructor(
    private gameClientService: GameClientService,
    private router: Router,
    private guiStateService: GuiStateService
  ) { }

  ngOnInit() {
    this.guiStateService.showMainLink = false;
    this.guiStateService.showBackLink = false;
    this.guiStateService.showLogout = true;
    this.guiStateService.titleBarText = "Select a Room";
    this.guiStateService.setZoom100();
  }

  public enterRoom(roomId: number): void {
    // reset GUI
    this.gameClientService.answerSentSuccessfully = false;

    if(this.gameClientService.state.previousCorrectAnswer) {
      this.gameClientService.state.previousCorrectAnswer.Cancelled = true;
    }

    this.gameClientService.enterRoom(roomId).subscribe(
      response => {

        // if we've changed rooms, remove previous answer so that we don't see previous answer from a different room.
        if (this.gameClientService.state.room != response.RoomId) {
          //this.gameClientService.state.previousCorrectAnswer.Cancelled = true;
          this.gameClientService.state.previousCorrectAnswer = 
          { AnsweredOnTime: false, CorrectOption: -1, PoseFound: false, Cancelled: true, Error: false };
        }

        this.gameClientService.state.room = response.RoomId;
        this.gameClientService.state.roomName = response.RoomName;

        console.log("joined " + response.RoomId + ", " + response.RoomName);
        console.log(response);

        // here, we could branch to one of several different gameplay type pages, eg 24/7 rolling, vs daily discrete page
        this.router.navigate(["gameplay"], { skipLocationChange: false });
      },
      error => {
        console.log("enterroom error message:");
        console.log(error);
        this.gameClientService.clearAuth();
      }
    )
  }

  public showStats(roomId: number): void {
    // we could do some fancy thing to pass in the roomId parameter with the navigation, but why not just use the gui service? it's easier.
    this.guiStateService.statsRoom = roomId;
    this.router.navigate(["stats"], { skipLocationChange: false });
  }

}
