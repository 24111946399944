import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { GuiStateService } from './gui-state.service';
import { WebWorkerService } from 'angular2-web-worker';
import { Router } from '@angular/router';
//import * as workerTimers from 'worker-timers';
//import { a } from '@angular/core/src/render3';



@Injectable({
  providedIn: 'root'
})
export class GameClientService {

  public previousAnswerEvent: Subject<any> = new Subject();
  public questionEndedEvent: Subject<any> = new Subject();

  public scoresGameplayUpdatedEvent: Subject<ScoreBoardResponseGameplay> = new Subject<ScoreBoardResponseGameplay>();
  public scoresDedicatedUpdatedEvent: Subject<ScoreBoardResponseDedicated> = new Subject<ScoreBoardResponseDedicated>();



  IP: string;
  PROTOCOL: string;

  UPDATE_COUNTDOWN_INTERVAL: number = 0.2 * 1000;
  ERROR_RETRY_WAIT: number = 3 * 1000;
  REQUEST_TIMEOUT: number = 3 * 1000;
  public SCOREBOARD_SAFETY_CONSTANT: number = 2000;
  MAX_NUMBER_OF_ROOMS = 1000;
  MAX_POSES_LENGTH = this.MAX_NUMBER_OF_ROOMS;

  email: string;
  password: string;
  token: string;
  displayName: string = "";
  signupData: any;
  state: GameState; // = this.initGameState();
  scores: ScoreBoardResponseGameplay; // the scores shown during gameplay, not on dedicated stats page
  seasonScores: ScoreBoardResponseGameplay; // the scores shown on the dedicated stats page
  currentStatsDetails: any;  // parameters used to get score details on dedi page


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
  };

  signupUrl: string;
  loginUrl: string;
  logoutUrl: string;
  selectRoomUrl: string;
  getQuestionUrl: string;
  answerQuestionUrl: string;
  correctAnswerUrl: string;
  keepAliveUrl: string;
  getSeasonsForRoomUrl: string;
  getScoresGameplayUrl: string;
  getScoresSeasonUrl: string;
  configureForServer: boolean;

  KEEPALIVE_INTERVAL: number = 5 * 60 * 1000;

  // if the answer was sent without error returned... this is used to determine whether to notify that verdict is on its way.
  answerSentSuccessfully: boolean = false;

  //KEEPALIVE_INTERVAL: number = 1 * 10 * 1000;


  constructor(
    private http: HttpClient,
    private guiStateService: GuiStateService,
    private webWorkerService: WebWorkerService,
    private router: Router
  ) {

    // TODO: #124904 update this domain.
    this.configureForServer = window.location.hostname.endsWith("dolphintrivia.com");

    //this.configureForServer = true;

    // try to reload token from localStorage in case the window was just refreshed, or closed not too long ago and then reopened.
    if (!this.token) {
      console.log("reloading login details from localstorage");
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");

      if (token && email) {
        this.token = token;
        this.email = email;
        this.router.navigate(["title"], { skipLocationChange: false });
      } else {
        this.clearAuth();
      }
    }


    if (this.configureForServer === true) {
      this.IP = 'dolphintrivia.com';
      this.PROTOCOL = 'https://';
    } else {
      this.IP = 'localhost';
      this.PROTOCOL = 'http://';
    }


    this.signupUrl = this.PROTOCOL + this.IP + ':22222/trivia/signup';
    this.loginUrl = this.PROTOCOL + this.IP + ':22222/trivia/login';
    this.logoutUrl = this.PROTOCOL + this.IP + ':22222/trivia/logout';
    this.selectRoomUrl = this.PROTOCOL + this.IP + ':22222/trivia/selectroom';
    this.getQuestionUrl = this.PROTOCOL + this.IP + ':22222/trivia/question';
    this.answerQuestionUrl = this.PROTOCOL + this.IP + ':22222/trivia/answer';
    this.correctAnswerUrl = this.PROTOCOL + this.IP + ':22222/trivia/getcorrectanswer';
    this.keepAliveUrl = this.PROTOCOL + this.IP + ':22222/trivia/keepalive';
    this.getSeasonsForRoomUrl = this.PROTOCOL + this.IP + ':22222/trivia/getseasonsforroom';
    this.getScoresGameplayUrl = this.PROTOCOL + this.IP + ':22222/trivia/getscoreboardgameplay';   // for scores during gameplay
    this.getScoresSeasonUrl = this.PROTOCOL + this.IP + ':22222/trivia/getscoreboardseason';   // for scores for season on dedi stats page



    this.initGameState();

    setInterval(() => {
      this.keepAlive()
    }, this.KEEPALIVE_INTERVAL);


    // start the game loop.
    setInterval(() => {
      this.updateGame()
    }, this.UPDATE_COUNTDOWN_INTERVAL);
  }

  setCurrentStatsDetails(currentStatsDetails: { startDateTime: string; endDateTime: string; roomId: number; }) {
    this.currentStatsDetails = currentStatsDetails;
  }

  setDisplayName(displayName: string) {
    this.displayName = displayName;
  }

  setEmail(email: string) {
    this.email = email;
    localStorage.setItem("email", email);
  }

  public getDurationUntilNextQuestion(): number {
    const dateNow = new Date(); // is utc
    const timeLeftMillis = Math.max(this.state.dateUtcToGetNextQuestion.getTime() - dateNow.getTime(), 0);
    return timeLeftMillis;
  }

  public getDurationToAnswerCurrentQuestion(allowNegative: boolean): number {
    const dateNow = new Date(); // is utc
    let timeLeftMillis;

    if (allowNegative == false) {
      timeLeftMillis = Math.max(this.state.dateUtcToAnswerQuestion.getTime() - dateNow.getTime(), 0);
    } else {
      timeLeftMillis = this.state.dateUtcToAnswerQuestion.getTime() - dateNow.getTime();
    }

    return timeLeftMillis;
  }


  // main game loop. runs every few milliseconds, and decides how to proceed to the next state.
  public updateGame(): void {

    if (this.state.screenState == ScreenStates.notInGamePlay) {
      return;
    }

    var dateNow = new Date(); // is utc
    var datePrevious = new Date(dateNow.getTime() - this.UPDATE_COUNTDOWN_INTERVAL);

    // this is the state it will be in immediately after getQuestion(), so make sure we don't call getQuestion() twice in a row
    if (this.state.screenState == ScreenStates.waitingForNextQuestion) {
      let waitingHadTimeLeftBefore: boolean = this.state.dateUtcToGetNextQuestion.getTime() > datePrevious.getTime();
      let waitingHasTimeLeftNow: boolean = this.state.dateUtcToGetNextQuestion.getTime() > dateNow.getTime();

      if (waitingHasTimeLeftNow == false && waitingHadTimeLeftBefore == true) {
        this.getQuestion();
      }


      else if (waitingHasTimeLeftNow == false && waitingHadTimeLeftBefore == false) {
        this.getQuestion();
        this.state.dateUtcToRetry = new Date(dateNow.getTime() + this.ERROR_RETRY_WAIT);
        this.state.screenState = ScreenStates.errorAndWaitingForNextQuestion;
      }
    }

    // the multiple choice buttons are up, and the player is waiting to click one.
    else if (this.state.screenState == ScreenStates.playerChoosingAnswer) {
      let questionHadTimeLeftBefore: boolean = this.state.dateUtcToAnswerQuestion.getTime() > datePrevious.getTime();
      let questionHasTimeLeftNow: boolean = this.state.dateUtcToAnswerQuestion.getTime() > dateNow.getTime();

      // time ran out this frame.
      if (questionHasTimeLeftNow == false && questionHadTimeLeftBefore == true) {
        this.setPreviousQuestionRanOutOfTime();
        this.getQuestion();
      }

      // time ran out last frame.
      else if (questionHasTimeLeftNow == false && questionHadTimeLeftBefore == false) {
        this.setPreviousQuestionRanOutOfTime();
        this.getQuestion();
        this.state.dateUtcToRetry = new Date(dateNow.getTime() + this.ERROR_RETRY_WAIT);
        this.state.screenState = ScreenStates.errorAndWaitingForNextQuestion;
      }
    }

    // player has clicked one of the multiple choice buttons.
    else if (this.state.screenState == ScreenStates.playerHasChosenAnswer) {

    }

    // player has just rejoined room soon after answering a question, and it is still during the answering period.
    else if (this.state.screenState == ScreenStates.playerChoosingAnswerButAlreadyAnswered) {

      let questionHadTimeLeftBefore: boolean = this.state.dateUtcToAnswerQuestion.getTime() > datePrevious.getTime();
      let questionHasTimeLeftNow: boolean = this.state.dateUtcToAnswerQuestion.getTime() > dateNow.getTime();

      if (questionHasTimeLeftNow == false && questionHadTimeLeftBefore == true) {
        this.setPreviousQuestionRanOutOfTime();
        this.getQuestion();
        this.state.previousCorrectAnswer.Cancelled = true;
      }

      else if (questionHasTimeLeftNow == false && questionHadTimeLeftBefore == false) {
        this.setPreviousQuestionRanOutOfTime();
        this.getQuestion();
        this.state.previousCorrectAnswer.Cancelled = true;

        this.state.dateUtcToRetry = new Date(dateNow.getTime() + this.ERROR_RETRY_WAIT);
        this.state.screenState = ScreenStates.errorAndWaitingForNextQuestion;
      }
      
    }

    // error was encountered.
    else if (this.state.screenState == ScreenStates.errorAndWaitingForNextQuestion) {
      let errorHadTimeLeftBefore: boolean = this.state.dateUtcToRetry.getTime() > datePrevious.getTime();
      let errorHasTimeLeftNow: boolean = this.state.dateUtcToRetry.getTime() > dateNow.getTime();

      if (errorHasTimeLeftNow == false && errorHadTimeLeftBefore == true) {
        this.state.dateUtcToRetry = new Date(dateNow.getTime() + this.ERROR_RETRY_WAIT);
        this.getQuestion();
      }
    }
  }

  setPreviousQuestionRanOutOfTime(): void {
    //alert("spqtf");
    // set answered on time false, only if it wasn't cancelled by leaving the room.
    if(this.state.previousCorrectAnswer.Cancelled === false) {
      this.state.previousCorrectAnswer = { AnsweredOnTime: false, CorrectOption: -1, PoseFound: false, Cancelled: false, Error: false };
    }

    this.state.previousUserSelectedAnswerNumber = -2;
    this.previousAnswerEvent.next();
    this.questionEndedEvent.next();
  }

  keepAlive() {
    let keepAliveData = { 'email': this.email, 'token': this.token };
    this.http.post<AnswerResponse>(this.keepAliveUrl, this.getFormUrlEncoded(keepAliveData), this.httpOptions).subscribe(
      response => {
        //        console.log("keep alive succeeded");
      },
      error => {
        //      console.log("keep alive error: " + error);
        this.clearAuth();
      }
    )
  }

  getFormUrlEncoded(toConvert) {
    const formBody = [];
    for (const property in toConvert) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(toConvert[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }

  // usually called when receiving error. ie we just bail out, and go back to login screen.
  public clearAuth(): void {
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    this.router.navigate(["/"], { skipLocationChange: false });
  }

  public logOut(): Observable<any> {
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    let logOutData = { 'email': this.email, 'token': this.token };
    return this.http.post(this.logoutUrl, this.getFormUrlEncoded(logOutData), this.httpOptions);
  }

  public enterRoom(room: number): Observable<any> {
    let loginData = { 'email': this.email, 'token': this.token, 'room': room };
    return this.http.post(this.selectRoomUrl, this.getFormUrlEncoded(loginData), this.httpOptions);
  }

  getScores(): ScoreBoardResponseGameplay {
    return this.scores;
  }


  // requests the scores for DURING GAMEPLAY... NOT the flexible one which takes parameters for any season, room, etc
  public requestGameplayScoreboard(period: number): void {
    let scoresData = { 'room': this.state.room, 'email': this.email, 'token': this.token, 'period': period };

    console.log("requesting gameplay scoreboard with:");
    console.log(scoresData);

    this.http.post<ScoreBoardResponseGameplay>(this.getScoresGameplayUrl, this.getFormUrlEncoded(scoresData), this.httpOptions).subscribe(
      scores => {
        this.scores = scores;
        console.log(scores);
        this.scoresGameplayUpdatedEvent.next(scores);
      },
      err => {
      }
    );
  }

  // requests the scores for the dedicated score (stats) viewing page
  public getSeasonScores(startDateTime: string, endDateTime: string, roomId: number, pageNumber: number, pageSize: number): void {
    let seasonData = {
      'room': roomId, 'token': this.token, 'email': this.email,
      'startDateTime': startDateTime, 'endDateTime': endDateTime, 'pageNumber': pageNumber, 'pageSize': pageSize
    };

    console.log("passing seasondata:");
    console.log(seasonData);

    this.http.post<ScoreBoardResponseDedicated>(this.getScoresSeasonUrl, this.getFormUrlEncoded(seasonData), this.httpOptions).subscribe(
      seasonScores => {
        console.log(seasonScores);
        this.scoresDedicatedUpdatedEvent.next(seasonScores);
        console.log("sent scores event");
      },
      err => {
      }
    );
  }

  setToken(token: string) {
    this.token = token;

    localStorage.setItem("token", token);
  }


  postLogin(email: string, password: string): Observable<any> {
    let loginData = { 'email': email, 'password': password };
    //    console.log(loginData)
    this.email = email;
    this.password = password;
    return this.http.post(this.loginUrl, this.getFormUrlEncoded(loginData), this.httpOptions);
  }

  postSignupInitial(email: string, password: string, displayName: string): Observable<any> {
    let signupData = { 'email': email, 'password': password, 'display_name': displayName };
    //    console.log(signupData)
    return this.http.post(this.signupUrl, this.getFormUrlEncoded(signupData), this.httpOptions);
  }

  public setStateNotPlaying(): void {
    this.state.screenState = ScreenStates.notInGamePlay;
  }

  getQuestion(): void {

    let getQuestionData = { 'email': this.email, 'token': this.token };

    this.state.screenState = ScreenStates.waitingForHttpResponse;

    this.state.dateUtcToGetNextQuestion = new Date(new Date().getTime() + this.REQUEST_TIMEOUT);

    console.log("attempting to get question");

    this.http.post<QuestionResponse>(this.getQuestionUrl, this.getFormUrlEncoded(getQuestionData), this.httpOptions).subscribe(
      question => {

        // there is time to wait until the next question, and there is no question to display right now.
        if (question.QuestionWaitFor > 0) {
          this.state.dateUtcToGetNextQuestion = new Date(new Date().getTime() + question.QuestionWaitFor);
          this.state.dateUtcToAnswerQuestion = new Date();
          this.state.screenState = ScreenStates.waitingForNextQuestion;
        }

        // there is no time to wait until the next question, and one is being displayed right now.
        else {
          this.state.dateUtcToAnswerQuestion = new Date(new Date().getTime() + question.TimeToAnswer);
          this.state.dateUtcToGetNextQuestion = new Date();
          this.state.question = question;

          //this.state.screenStates = 1;
          //this.state.screenStates = ScreenStates.playerChoosingAnswer;


          // if the question wasn't answered already (recently), present the choices.
          if (this.state.previousPosesAnswered.includes(question.PoseId) == false) {
            this.state.screenState = ScreenStates.playerChoosingAnswer;
          }

          // else the question was answered already, but don't present the choices.
          // also don't show the previous correct answer, because the answer never came.
          else {
            //this.state.screenState = ScreenStates.playerChoosingAnswer;
            //this.state.screenState = ScreenStates.playerHasChosenAnswer;
            this.state.screenState = ScreenStates.playerChoosingAnswerButAlreadyAnswered;
            this.state.previousCorrectAnswer.Cancelled = true;
            //alert("5");
          }







        }
      },
      err => {
        this.state.question = null;
        this.state.screenState = 3;
        this.state.dateUtcToRetry = new Date(new Date().getTime() + this.ERROR_RETRY_WAIT);
        console.log("error getting question");
        this.clearAuth();
      }
    );
  }


  public getCorrectAnswer(poseId: number): void {
    let correctAnswerData = { 'email': this.email, 'token': this.token, 'pose_id': poseId };

    //alert("gca");

    this.http.post<CorrectAnswerResponse>(this.correctAnswerUrl, this.getFormUrlEncoded(correctAnswerData), this.httpOptions).subscribe(
      answer => {
        this.state.previousCorrectAnswer.CorrectOption = answer.CorrectOption;
        this.state.previousCorrectAnswer.PoseFound = answer.PoseFound;

        console.log("correct option from server: " + answer.CorrectOption);

        this.previousAnswerEvent.next();
      },
      error => {
        this.state.previousCorrectAnswer = { CorrectOption: -1, PoseFound: false, AnsweredOnTime: false, Cancelled: false, Error: true };
      }
    );

  }

  public answerQuestion(option: number, poseId: number): void {

    console.log("answering question with poseId: " + poseId);

    let answerData = { 'email': this.email, 'token': this.token, 'guess': option, 'pose_id': poseId };

    this.state.screenState = ScreenStates.playerHasChosenAnswer;
    this.state.previousUserSelectedAnswerNumber = option;

    setTimeout(
      () => {
        this.questionEndedEvent.next();
        this.getCorrectAnswer(poseId);
      }
      ,
      this.getDurationToAnswerCurrentQuestion(false) + this.SCOREBOARD_SAFETY_CONSTANT
    );

    this.http.post<AnswerResponse>(this.answerQuestionUrl, this.getFormUrlEncoded(answerData), this.httpOptions).subscribe(
      answer => {
        this.state.previousQuestion = this.state.question;

        // set Cancelled to true because this is a dummy, 
        // and then replace it with false when we receive an answer from the server.
        // that way, if the player leaves the room and comes back in, it was "cancelled".
        this.state.previousCorrectAnswer = { AnsweredOnTime: answer.AnsweredOnTime, CorrectOption: -1, PoseFound: true, Cancelled: false, Error: false };

        this.state.screenState = ScreenStates.waitingForNextQuestion;
        this.state.dateUtcToGetNextQuestion = new Date(new Date().getTime() + answer.QuestionWaitFor);


        this.state.previousPosesAnswered.push(this.state.question.PoseId);
        if (this.state.previousPosesAnswered.length > this.MAX_POSES_LENGTH) {
          this.state.previousPosesAnswered.shift();
        }

        this.answerSentSuccessfully = true;
      },
      err => {
        this.state.question = null;
        this.state.screenState = 3;
        this.state.dateUtcToRetry = new Date(new Date().getTime() + this.ERROR_RETRY_WAIT);
        this.answerSentSuccessfully = false;
      }
    );
  }

  getSeasons(roomId: number): Observable<string[]> {
    this.state.screenState = ScreenStates.playerHasChosenAnswer;
    let loginData = { 'email': this.email, 'token': this.token, 'roomid': roomId };
    return this.http.post<string[]>(this.getSeasonsForRoomUrl, this.getFormUrlEncoded(loginData), this.httpOptions);
  }

  initGameState(): any {
    let gameState: GameState = new GameState();

    //    gameState.screenStates = 0;
    gameState.screenState = -1;

    gameState.dateUtcToGetNextQuestion = new Date();
    this.displayName = "";
    this.state = gameState;
  }

  resetLoginState(): any {
    this.initGameState();
  }
}

export class Season {
  startDateTime: Date;
  endDateTime: Date;
  roomId: number;
}

export class ScoreBoardEntryDedicated {
  Position: number;
  DisplayName: string;
  Correct: number;
  Me: boolean;
}

export class ScoreBoardResponseDedicated {
  SeasonFinished: boolean;
  RoomId: number;
  DateUtcStart: Date;
  DateUtcEnd: Date;
  Scores: ScoreBoardEntryDedicated[];
  Length: number;
}

export class ScoreBoardEntryGameplay {
  DisplayName: string;
  Correct: number;
  Me: boolean; // needed, because screen names are not unique and we don't want to send the real id, the email
}

export class ScoreBoardResponseGameplay {
  RoomId: number;
  TimePeriodSeconds: number;
  DateUtcStart: Date;
  DateUtcEnd: Date;
  Scores: ScoreBoardEntryGameplay[];
}

export class QuestionResponse {
  Question: string;
  Options: string[];
  QuestionWaitFor: number;  // milliseconds
  TimeToAnswer: number;
  PoseId: number;
  Category: string;
}

// used for view only, to display previous correct answer. 
// it gets its individual members filled in at different times. 
// it is not a response from an API itself!!!!!!!
export class PreviousCorrectAnswer {
  CorrectOption: number;
  AnsweredOnTime: boolean;
  PoseFound: boolean;
  Cancelled: boolean;
  Error: boolean;
}

export class CorrectAnswerResponse {
  CorrectOption: number;
  PoseFound: boolean;
}

export class AnswerResponse {
  QuestionWaitFor: number;
  AnsweredOnTime: boolean;
}

export class GameState {
  previousCorrectAnswer: PreviousCorrectAnswer;
  previousUserSelectedAnswerNumber: number; // 1-based
  screenState: ScreenStates;
  question: QuestionResponse;
  previousQuestion: QuestionResponse;
  dateUtcToGetNextQuestion: Date;
  dateUtcToAnswerQuestion: Date;
  dateUtcToRetry: Date;
  room: number;
  roomName: string;
  previousPosesAnswered: Array<number> = new Array<number>();
}

enum ScreenStates {
  notInGamePlay = -1,

  // there is time to wait until next question.
  waitingForNextQuestion = 0,

  // there is no time to wait until the next question because the answering period is right now.
  playerChoosingAnswer = 1,

  // the answer has been sent to the server. ie the API call has been made.
  // typically the program isn't in this state long (less than a second),
  // because as soon as the response comes back, it will advance to another state.
  playerHasChosenAnswer = 2,

  // dunno.
  errorAndWaitingForNextQuestion = 3,

  // this state is engaged as soon as GetQuestion() is called.
  waitingForHttpResponse = 4,

  // there is no time to wait until the next question because the answering perio is right now, 
  // but player has already answered it. typically used when re-entering the room very soon after answering the question.
  playerChoosingAnswerButAlreadyAnswered = 5
}
