import { Component, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { GameClientService } from 'src/app/game-client.service';
import { RouterModule, Router } from '@angular/router';
import { GuiStateService } from '../gui-state.service';
import { GameplayScoreTableComponent } from '../gameplay-score-table/gameplay-score-table.component';
import Moment from 'moment-timezone';


@Component({
  selector: 'app-gameplay',
  templateUrl: './gameplay.component.html',
  styleUrls: ['./gameplay.component.css'],
})

export class GameplayComponent implements OnInit, OnDestroy {

  public scoreTableVisible: boolean;
  public timeUntilNextQuestion: string;
  private intervalId: any = null;
  public timeToAnswerQuestionNumber: number;
  public timeToAnswerQuestionString: string;
  public timeToWaitForResult: string;
  public timeToWaitForResultNumber: number;


  constructor(
    public gameClientService: GameClientService,
    private router: Router,
    private guiStateService: GuiStateService,
    private changeDetector: ChangeDetectorRef
  ) {

  }

  ngOnDestroy() {
    this.gameClientService.setStateNotPlaying();
    clearInterval(this.intervalId);
  }

  ngOnInit() {
    this.guiStateService.showMainLink = true;
    this.guiStateService.showBackLink = true;
    this.guiStateService.showLogout = true;
    this.guiStateService.titleBarText = this.gameClientService.state.roomName;
    this.guiStateService.setZoom100();


    this.gameClientService.getQuestion(); // this automatically sets the gameState to waitingForNextQuestion     

    this.intervalId = setInterval(() => {
      this.getTimeToAnswerQuestion();
      this.getTimeUntilNextQuestion();
      this.getTimeToWaitForResult();
    },
      100
    )
  }


  public getTimeToWaitForResult(): void {
    this.timeToWaitForResultNumber = ((this.gameClientService.getDurationToAnswerCurrentQuestion(true) + 
    this.gameClientService.SCOREBOARD_SAFETY_CONSTANT) / 1000);
    this.timeToWaitForResult = this.timeToWaitForResultNumber.toFixed(1);
  }

  public getShowTimeToResult(): boolean {
    return this.gameClientService.answerSentSuccessfully && this.timeToWaitForResultNumber >= 0.0;
  }

  public getTimeToAnswerQuestion(): void {
    this.timeToAnswerQuestionString = (this.gameClientService.getDurationToAnswerCurrentQuestion(false) / 1000).toFixed(1);
  }

  public getTimeUntilNextQuestion(): void {
    this.timeUntilNextQuestion = (this.gameClientService.getDurationUntilNextQuestion() / 1000).toFixed(1);
  }

  sendAnswer(option: number): void {
    this.gameClientService.answerQuestion(option, this.gameClientService.state.question.PoseId);
  }

  previousAnswerExists(): boolean {
    return this.gameClientService.state.previousCorrectAnswer != undefined;
  }

  previousAnswerCorrect(): boolean {
    return this.gameClientService.state.previousUserSelectedAnswerNumber == this.gameClientService.state.previousCorrectAnswer.CorrectOption;
  }

}
