<div class="toolbar-black-bg">
  <table class="title-nav-table">
    <tr>
      <td class="title-nav-left-column">
        <div class="title-left" style="display: flex; flex-flow: row; justify-content: flex-start; align-items: center;">
          <button mat-raised-button class="round-corners-nav-buttons" color="primary" (click)="goBack();" *ngIf="guiStateService.showBackLink">Back</button>
      </div>
      </td>
      <td class="title-nav-center-column" >
        <div class="title-centre" style="display: flex; flex-flow: row; justify-content: center; align-items: center;">
          {{guiStateService.titleBarText}}
        </div>
      </td>

      <!--
        TODO: #912407 - maybe only enable this in desktop mode, or if otherwise there's enough horizontal space for it.
      <td class="title-nav-username-column">
        <div class="title-right" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <span style="margin-right: 3vw; font-size: 0.75vw;">{{gameClientService.displayName}}</span>
        </div>
      </td>
    -->
    

      <td class="title-nav-right-column">
        <div class="title-right" style="display: flex; flex-flow: row; justify-content: flex-end; align-items: center;">
          <span style="width: 10%;"></span><button class="round-corners-nav-buttons" mat-raised-button color="primary" (click)="signOut()" *ngIf="guiStateService.showLogout">Logout</button>
        </div>
      </td>
    </tr>
  </table>

</div>
