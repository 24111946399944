<div class="fluid-font-size" style="height: 100%;">


  <div *ngIf="isPreviousAvailable == false || isCancelled == true" class="d-flex flex-row w-100 justify-content-center previous-no-previous"
    style="height: 100%;"> </div>


  <div *ngIf="isPreviousAvailable == true && isTooLate == true"
    class="previous-too-late d-flex flex-column w-100 justify-content-center previous-no-previous"
    style="height: 100%;">

    <div style="height: 33%;"></div>
    <div style="height: 33%;"></div>

    <div class="d-flex flex-row justify-content-center align-items-center chosen-answer-row-incorrect w-100"
      style="height: 33%; border-radius: 10px;">
      Too late!
    </div>
  </div>


  <div *ngIf="isPreviousAvailable == true && isTooLate == false" style="height: 100%;">
    <table class="w-100" style="height: 100%;">


      <tr class="previous-question-row w-100 previous-common" style="height: 33%; ">
        <td class="w-25" style="border-top-left-radius: 10px; ">
          Previous question:
        </td>
        <td style="border-top-right-radius: 10px; ">
          {{ previousQuestion }}
        </td>
      </tr>


      <tr class="correct-answer-row previous-common" style="height: 33%; ">
        <td class="w-25" style="border-bottom-left-radius: 10px; ">
          Correct answer:
        </td>
        <td style="border-bottom-right-radius: 10px;">
          {{ correctAnswer }}
        </td>
      </tr>


      <tr [ngClass]="isCorrect ? 'chosen-answer-row-correct' : 'chosen-answer-row-incorrect' " class="previous-common"
        style="height: 33%;">
        <td class="w-25" style="border-top-left-radius: 10px; border-bottom-left-radius: 10px;">
          Your answer:
        </td>
        <td style="border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
          {{ chosenAnswer }}
        </td>
      </tr>
    </table>
  </div>


</div>