import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { GameClientService } from '../game-client.service';
import { GuiStateService } from '../gui-state.service';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-title-navigation-bar',
  templateUrl: './title-navigation-bar.component.html',
  styleUrls: ['./title-navigation-bar.component.css']
})
export class TitleNavigationBarComponent implements OnInit, OnDestroy {

  logOutSubscription: Subscription;

  constructor(
    public router: Router,
    public guiStateService: GuiStateService,
    public gameClientService: GameClientService,
    public location: Location
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() { 
  }

  public goBack(): void {
    console.log("going back");
    this.location.back();
  }

  public signOut() {
    console.log("logout");
    this.logOutSubscription = this.gameClientService.logOut().subscribe(
      response => {
        this.gameClientService.resetLoginState();
        this.logOutSubscription.unsubscribe();
        this.router.navigate(["/"], { skipLocationChange : false });
      }       
    );
  }
}
