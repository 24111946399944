import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class GuiStateService {

  public showMainLink: boolean = false;
  public showBackLink: boolean = false;
  public showLogout: boolean = false;
  public titleBarText: string = "";
  public statsRoom: number = 0;

  constructor(private meta: Meta) { }




  // #298734
  public setZoom100(): void { 
    // document.body.style.zoom = "100%";
    this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0' });
  }

}
