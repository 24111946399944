<div align="center" class="stats-text set-max-width" style="height: 100%; margin: 0 auto">
    <app-title-navigation-bar></app-title-navigation-bar>

  <div style="width: 100%;">
    <span>Select a season to view statistics:</span>

    <!-- change to grid of squares, probably using css flex or try out css grid. whilst doing so, display the summary value on the square, eg "10th", meaning the logged in player was placed 10th, for that season. -->
    <div *ngIf="isLoading == false" style="display: flex; flex-direction: row; flex-wrap: wrap; width: 100%;">

      <!-- since ngFor is on the div, many divs will appear -->
      <div *ngFor="let season of seasonsForRoom" class="season-div">
          <button class="stats-timeframe" mat-raised-button color="primary"
          (click)="openStats(season.StartDateTime, season.EndDateTime, selectedRoomId)"
          style="display: flex; flex-flow: row; justify-content: center; align-items: center;">{{ season.StartDateTime | date: 'MMM-yyyy' }}
        </button>

        </div>
    </div>
  </div>

</div>
