<div *ngIf="scores" style="height: 100%;">
  <table class="score-table">

    <!-- head -->
    <thead>
      <tr class="one-thirty-third-height">
        <th class="score-header" colspan="2" style="border-top-left-radius: 10px; border-top-right-radius: 10px;">Last {{ timePeriodString }}</th>
        </tr>
      <tr>
        <th class="display-name-header">Name</th>
        <th class="correct-header">Points</th>
      </tr>
    </thead>

    <!-- body -->
    <tbody >
      <tr *ngFor="let score of scores.Scores" class="one-thirty-third-height">
        <td [ngClass]="score.Me === false ? 'display-name' : 'display-name-me' ">{{score.Position !== -1 ? score.DisplayName : ""}}</td>
        <td [ngClass]="score.Me === false ? 'correct' : 'correct-me' ">{{score.Position !== -1 ? score.Correct : ""}}</td>
      </tr>

    <!-- footer -->
    <tr class="one-thirty-third-height">
      <td class="score-footer" colspan="2" style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;"></td>
    </tr>
  </tbody>

  </table>
</div>
