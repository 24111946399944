<div style="display: flex; flex-flow: row; justify-content: space-between; height: 100%; min-height: 100%; width: 100%; min-width: 100%;">

  <div class="score-left">
    <div style="height: 10%; min-height: 10%;"></div>
    <div class="score-table">
      <app-gameplay-score-table [timePeriodString]="'Day'"></app-gameplay-score-table>
    </div>
  </div>

  <div class="black-bg flex-column d-flex justify-content-center set-max-width" style="height: 100%; min-height: 100%;">

    <app-title-navigation-bar style="height: 10%; max-height: 10%;"></app-title-navigation-bar>

    <div class="flex-row d-flex justify-content-center w-100" style="background: #000000; height: 90%;">

      <div class="flex-column d-flex justify-content-center w-100" style="height: 100%;">

<!--        
        <p style="color: #FFFFFFFF">state: {{ gameClientService.state.screenState }}</p>
-->    
        
        <!-- status line, eg "Waiting for next question" -->
    <!--    <div class="flex-row d-flex justify-content-center w-100" style="height: 5%; align-items: center;">-->
          <div class="flex-column d-flex justify-content-center w-100" style="height: 10%; align-items: center;"> 

          <div *ngIf="
           gameClientService.state.screenState === 0 ||
           gameClientService.state.screenState === 2 
           " 
           class="waiting w-100 round-corners-top" 
           style="height: 100%;">
            Waiting for next question: {{ timeUntilNextQuestion }}
          </div>

          <div *ngIf="
          gameClientService.state.screenState === 0 && 
          getShowTimeToResult()
          " 
          class="waiting w-100 round-corners-top" 
          style="height: 100%;">
            Waiting for result: {{ timeToWaitForResult }}
          </div>

          <div *ngIf="
          gameClientService.state.screenState === 1
          " 
          class="waiting w-100 round-corners-top" 
          style="height: 100%;">
            Time to answer this question: {{ timeToAnswerQuestionString }}
          </div>

          <div *ngIf="
          gameClientService.state.screenState === 5
          " 
          class="waiting w-100 round-corners-top" 
          style="height: 100%;">
            Already answered current question. Waiting for next question...
          </div>

          <!-- waiting for http result. very quickly gone usually. -->
          <div *ngIf="
          gameClientService.state.screenState === 4
          " 
          class="waiting w-100 round-corners-top" 
          style="height: 100%;">
            
          </div>


        </div>

        <!-- Question followed by answer buttons -->
        <div class="flex-row d-flex justify-content-center w-100 question-and-answers">
          <div class="flex-column d-flex justify-content-center w-100" style="height: 100%;">

            <div class="flex-row d-flex justify-content-center w-100" style="height: 100%;">
              <div *ngIf="gameClientService.state.screenState == 1"
                class="question-container flex-column d-flex justify-content-center w-100"
                style="height: 100%; align-content: center;">

                <!-- question -->
                <div class="question-text round-corners-bottom" style="height: 20%;">
                  {{ gameClientService.state.question.Category}}: {{gameClientService.state.question.Question }}
                </div>

                <!-- button 1 -->
                <div class="question-button-container-div flex-row d-flex justify-content-center" style="height: 20%;">
                  <button mat-raised-button class="question-option" (click)="sendAnswer(1)">
                    {{ gameClientService.state.question.Options[0] }}
                  </button>
                </div>

                <!-- button 2 -->
                <div class="question-button-container-div" style="height: 20%;">
                  <button mat-raised-button class="question-option" (click)="sendAnswer(2)">
                    {{ gameClientService.state.question.Options[1] }}
                  </button>
                </div>

                <!-- button 3 -->
                <div class="question-button-container-div" style="height: 20%;">
                  <button mat-raised-button class="question-option" (click)="sendAnswer(3)"
                    *ngIf="gameClientService.state.question.Options[2] != ''">
                    {{ gameClientService.state.question.Options[2] }}
                  </button>
                </div>

                <!-- button 4 -->
                <div class="question-button-container-div" style="height: 20%;">
                  <button mat-raised-button class="question-option" (click)="sendAnswer(4)"
                    *ngIf="gameClientService.state.question.Options[3] != ''">
                    {{ gameClientService.state.question.Options[3] }}
                  </button>
                </div>
              </div>


              <!-- shown between questions -->
              <div
                *ngIf="
                gameClientService.state.screenState === 0 || 
                gameClientService.state.screenState === 2 || 
                gameClientService.state.screenState === 3 || 
                gameClientService.state.screenState === 4 || 
                gameClientService.state.screenState === 5
                "
                class="question-container-blank w-100"
                style="height: 100%; display: flex; flex-direction: column; align-content: center;">
                <div class="question-container-bottomhalf" style="height: 100%;">

                  <div class="question-text round-corners-bottom "
                    *ngIf="gameClientService.state.screenState === 0 || gameClientService.state.screenState === 4"
                    style="height: 20%; ">
                  </div>

                  <div class="question-text-blank"
                    *ngIf="gameClientService.state.screenState === 2 || gameClientService.state.screenState === 3"
                    style="height: 20%;">
                  </div>

                  <div class="question-text-blank"
                  *ngIf="gameClientService.state.screenState === 5"
                  style="height: 20%;">
                  </div>

                  <div class="question-button-container-div" style="height: 20%;">

                  </div>
                  <div class="question-button-container-div" style="height: 20%;">

                  </div>
                  <div class="question-button-container-div" style="height: 20%;">

                  </div>
                  <div class="question-button-container-div" style="height: 20%;">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="previous-answer">
          <app-previous-answer-status></app-previous-answer-status>
        </div>

      </div>
    </div>
  </div>

  <div class="score-right">
    <div style="height: 10%; min-height: 10%;"></div>
    <div class="score-table">
      <app-gameplay-score-table [timePeriodString]="'Hour'"></app-gameplay-score-table>
    </div>
  </div>

</div>