<div align="center" class="stats-details-text set-max-width" style="height: 100%; margin: 0 auto; min-height: 100%;">

  <app-title-navigation-bar></app-title-navigation-bar>

  <div *ngIf="wholeSeason">
    Statistics for Season {{startPeriod | date: 'MMM-yyyy' }} {{ completedNotifier }}
  </div>

  <div *ngIf="!wholeSeason">
    Statistics for period {{startPeriod}} to {{endPeriod}}
  </div>

  <div *ngIf="scoreboard" style="width: 100%;">
    <mat-paginator 
    [pageSizeOptions]="[5, 10]" 
    [length]="length1"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="getScoresPage($event);" 
    showFirstLastButtons
    style="background-color: #404040; color: white;"
    >
    </mat-paginator>

    <table mat-table [dataSource]="scoreboard.Scores" class="mat-elevation-z8">

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Position </th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.Me === false ? 'rowcick' : 'rowcick-me' ">
          {{element.Position !== -1 ? element.Position : ""}} </td>
      </ng-container>

      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef> Display Name </th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.Me === false ? 'rowcick' : 'rowcick-me' ">
          {{element.Position !== -1 ? element.DisplayName : ""}} </td>
      </ng-container>

      <ng-container matColumnDef="correct">
        <th mat-header-cell *matHeaderCellDef> Score </th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.Me === false ? 'rowcick' : 'rowcick-me' ">
          {{element.Position !== -1 ? element.Correct : ""}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


  </div>

</div>