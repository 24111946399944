import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule, MatInputModule, MatButtonModule, MatToolbarModule, MatTableModule, MatPaginatorModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Routes, Router, RouterModule } from '@angular/router';
import { TitleScreenComponent } from './title-screen/title-screen.component';
import { AppRoutingModule } from './/app-routing.module';
import { GameplayComponent } from './gameplay/gameplay.component';
import { StatsComponent } from './stats/stats.component';
import { TitleNavigationBarComponent } from './title-navigation-bar/title-navigation-bar.component';
import { GameplayScoreTableComponent } from './gameplay-score-table/gameplay-score-table.component';
import { WebWorkerService } from 'angular2-web-worker';
import { PreviousAnswerStatusComponent } from './previous-answer-status/previous-answer-status.component';
import { StatsDetailsComponent } from './stats-details/stats-details.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TitleScreenComponent,
    GameplayComponent,
    StatsComponent,
    TitleNavigationBarComponent,
    GameplayScoreTableComponent,
    PreviousAnswerStatusComponent,
    StatsDetailsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule
  ],
  providers: [WebWorkerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
