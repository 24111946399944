import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { GameClientService, Season } from 'src/app/game-client.service';
import { RouterModule, Router } from '@angular/router';
import { GuiStateService } from 'src/app/gui-state.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit, OnDestroy {

  selectedSeason: Season;
  selectedRoomId: number;

  seasonsForRoom: string[];
  isLoading: boolean = true;

  seasonsSubscription: Subscription;

  constructor(
    private gameClientService: GameClientService,
    private router: Router,
    private guiStateService: GuiStateService
  ) { }

  ngOnInit() {
    this.guiStateService.showBackLink = true;
    this.guiStateService.titleBarText = "Statistics";
    this.guiStateService.setZoom100();

    this.selectedRoomId = this.guiStateService.statsRoom;

    this.getSeasons();
  }

  ngOnDestroy() {
    if(this.seasonsSubscription)
      this.seasonsSubscription.unsubscribe();
  }

  public openStats(startDateTime: string, endDateTime: string, roomId: number) {
    const currentStatsDetails = { startDateTime, endDateTime, roomId, wholeSeason: true };
    this.gameClientService.setCurrentStatsDetails(currentStatsDetails);
    this.router.navigate(["statsdetails"], { skipLocationChange: false });
  }

  public getSeasons(): void {
    this.isLoading = true;

    this.seasonsSubscription = this.gameClientService.getSeasons(this.selectedRoomId).subscribe(
      seasons => {
        this.seasonsForRoom = seasons;
        console.log(seasons);
        this.isLoading = false;
      },
      error => {
        console.log("error getting seasons");
        this.gameClientService.clearAuth();
      }
    );
  }
}
